import React from 'react';

function Home() {
  return (
	<div>
	  <h1>Welcome to the Travel Portal</h1>
	  <p>Explore the world with us!</p>
	</div>
  );
}

export default Home;