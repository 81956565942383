import React from 'react';

function Destinations() {
  return (
	<div>
	  <h1>Destinations</h1>
	  <p>Discover our amazing destinations.</p>
	</div>
  );
}

export default Destinations;