import React from 'react';

function Booking() {
  return (
	<div>
	  <h1>Booking</h1>
	  <p>Book your next adventure with us.</p>
	</div>
  );
}

export default Booking;